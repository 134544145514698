<script>
import { layoutComputed } from "@/state/helpers";

import Vertical from "./vertical";

export default {
    components: {
        Vertical,
    },
    props: {
      items: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
    },

};
</script>

<template>
  <div>
    <Vertical :items="items">
      <slot />
    </Vertical>
  </div>
</template>
