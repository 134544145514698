<template>
  <footer class="footer py-2 d-flex">
    <div class="container-fluid my-auto">
      <div class="row">
        <div class="col-sm-6">
          {{ new Date().getFullYear() }} © Smart Fleet Management System
        </div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-lg-block">
            Design & Develop by PT. Lunar Inovasi Teknologi - a Polytron Company
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
