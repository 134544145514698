<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div
        class="navbar-header"
        style="height: 60px"
      >
        <div class="d-flex">
          <div class="d-flex d-md-none">
            <button
              id="topnav-hamburger-icon"
              type="button"
              class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none"
            >
              <span class="hamburger-icon">
                <span />
                <span />
                <span />
              </span>
            </button>
          </div>
          <div class="d-flex">
            <router-link
              class="d-flex"
              to="/"
            >
              <img
                src="@/assets/images/logo/polytron-logo.png"
                alt=""
                height="14"
                class="my-auto mx-auto"
              >
            </router-link>
            <div class="d-none d-lg-flex">
              <span class="ms-3 fw-bold fs-18">
                SMART FLEET MANAGEMENT SYSTEM
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div
            class="d-none d-md-flex ms-1 me-3 header-item"
            style="border-right: 1px solid #CED4DA;"
          >
            <button
              class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none d-none d-xl-flex"
              data-toggle="fullscreen"
              @click="initFullScreen"
            >
              <i class="bx bx-fullscreen fs-22" />
            </button>
          </div>
          <div class="d-none d-md-flex">
            <b-input-group class="mb-0">
              <template #prepend>
                <b-input-group-text
                  class="prepend-outline-secondary"
                  style="background-color: transparent;"
                >
                  Organization
                </b-input-group-text>
              </template>
              <OrganizationSelector />
            </b-input-group>
          </div>

          <div
            class="dropdown ms-sm-3 header-item topbar-user bg-white"
            style="border-left: 1px #CED4DA solid; border-right: 1px #CED4DA solid;"
          >
            <div
              id="page-header-user-dropdown"
              type="button"
              class="btn shadow-none"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div class="d-none d-lg-block text-start ms-xl-2">
                <div class="fw-medium user-name-text">
                  {{ profile.name }}
                </div>
                <div class="fw-medium text-muted user-name-sub-text">
                  {{ organizationRole }}
                </div>
              </div>
              <i
                class="mdi mdi-account-circle text-muted d-flex d-lg-none fs-1"
              />
            </div>
            <div class="dropdown-menu dropdown-menu-end">
              <!-- item-->
              <h6 class="px-3 pt-2">
                {{ profile.name }}
              </h6>
              <div class="d-flex d-lg-none px-3 pb-2 fw-medium text-muted user-name-sub-text">
                {{ organizationRole }}
              </div>
              <router-link
                class="dropdown-item"
                to="/profile"
              >
                <i class="mdi mdi-account-circle text-muted fs-16 align-middle me-1" />
                <span class="align-middle border-0">Profile</span>
              </router-link>
              <router-link
                v-if="userRole < 1"
                class="dropdown-item"
                to="/settings"
              >
                <i class="mdi mdi-cog text-muted fs-16 align-middle me-1" />
                <span class="align-middle border-0">Settings</span>
              </router-link>
              <a
                class="dropdown-item"
                href="/logout"
              ><i class="mdi mdi-logout text-muted fs-16 align-middle me-1" />
                <span
                  class="align-middle"
                  data-key="t-logout"
                >Sign Out</span></a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="navbar-header text-white bg-dark"
        style="height: 40px;"
      >
        <div
          class="d-none d-md-flex text-white"
          style="font-size: 15px;"
        >
          <div>
            <router-link
              class="nav-link menu-link ps-0"
              :class="isParentNode('dashboard') ? 'active router-link-active' : ''"
              to="/"
            >
              <span>DASHBOARD</span>
            </router-link>
          </div>
          <div>
            <div class="nav-link menu-link p-0">
              <div
                type="button"
                class="nav-link menu-link"
                :class="isParentNode('fleet') ? 'active router-link-active' : ''"
                data-bs-toggle="dropdown"
              >
                <span>FLEET <i class="bx bx-chevron-down" /> </span>
              </div>
              <div
                class="dropdown-menu dropdown-menu-start bg-primary py-0 animation-down"
                style="border-radius: 0;"
              >
                <router-link
                  class="nav-link menu-link"
                  to="/fleet-management"
                >
                  <span>Fleet Management</span>
                </router-link>
                <router-link
                  class="nav-link menu-link"
                  to="/fleet-realtime"
                >
                  <span>Realtime Status</span>
                </router-link>
                <router-link
                  class="nav-link menu-link"
                  to="/fleet-emission"
                >
                  <span>Carbon Emission Report</span>
                </router-link>
              </div>
            </div>
          </div>
          <div>
            <router-link
              class="nav-link menu-link"
              :class="isParentNode('site') ? 'active router-link-active' : ''"
              to="/site"
            >
              <span>SITE</span>
            </router-link>
          </div>
          <div>
            <router-link
              class="nav-link menu-link"
              :class="isParentNode('organization') ? 'active router-link-active' : ''"
              :to="profile.is_superadmin ? '/organization' : '/organization-detail'"
            >
              <span>ORGANIZATION</span>
            </router-link>
          </div>
        </div>
        <div class="ms-3">
          <ol
            class="breadcrumb m-0"
            style="font-size: 14px;"
          >
            <li
              v-for="(item, index) in items"
              :key="index"
              class="breadcrumb-item"
              :class="{ active: item[0] }"
            >
              <span v-if="index == items.length-1">
                {{ item.text }}
              </span>
              <router-link 
                v-else
                :to="item.href"
              >
                {{ item.text }}
              </router-link>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
  import { mapState, mapGetters } from 'vuex';
  import OrganizationSelector from '@/components/OrganizationSelector.vue';

  export default {
    components: {
      OrganizationSelector
    },
    props: {
      items: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    data() {
      return {
        text: null,
        value: null,
        myVar: 1,
      };
    },
    computed: {
      ...mapGetters('organization', ['userRole']),
      ...mapState('auth', ['profile']),
      ...mapState('organization', ['selectedOrganization']),
      
      isIndexRoute() {
        const routeIndexList = ['organization-index', 'device-index', 'user-index', 'device'];
        return routeIndexList.includes(this.$route.name);
      },

      organizationRole() {
        if (this.profile.is_superadmin) {
          return 'Superadmin'
        }
        if (this.selectedOrganization) {
          const userOrganization = this.profile.organizations.map(item => item.pivot)
          const selectedOrganization = userOrganization.find(item => item.organization_id == this.selectedOrganization)
          return selectedOrganization.organization_role ?? ''
        }
        return ''
      },

      selectedOrganization: {
        get() {
          return this.$store.state.organization.selectedOrganization;
        },
      },

    },
    mounted() {
      document.addEventListener("scroll", function () {
        var pageTopbar = document.getElementById("page-topbar");
        if (pageTopbar) {
          document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50 ? pageTopbar.classList.add(
            "topbar-shadow") : pageTopbar.classList.remove("topbar-shadow");
        }
      });
      if (document.getElementById("topnav-hamburger-icon"))
        document
        .getElementById("topnav-hamburger-icon")
        .addEventListener("click", this.toggleHamburgerMenu);

      // this.isCustomDropdown();
    },
    methods: {
      isParentNode(name) {
        const routeName = this.$route.name;
        const parentName = routeName.split('-')[0]
        return parentName == name
      },

      toggleHamburgerMenu() {
        var windowSize = document.documentElement.clientWidth;

        if (windowSize > 767)
          document.querySelector(".hamburger-icon").classList.toggle("open");

        //For collapse horizontal menu
        if (
          document.documentElement.getAttribute("data-layout") === "horizontal"
        ) {
          document.body.classList.contains("menu") ?
            document.body.classList.remove("menu") :
            document.body.classList.add("menu");
        }

        //For collapse vertical menu
        if (document.documentElement.getAttribute("data-layout") === "vertical") {
          if (windowSize < 1025 && windowSize > 767) {
            document.body.classList.remove("vertical-sidebar-enable");
            document.documentElement.getAttribute("data-sidebar-size") == "sm" ?
              document.documentElement.setAttribute("data-sidebar-size", "") :
              document.documentElement.setAttribute("data-sidebar-size", "sm");
          } else if (windowSize > 1025) {
            document.body.classList.remove("vertical-sidebar-enable");
            document.documentElement.getAttribute("data-sidebar-size") == "lg" ?
              document.documentElement.setAttribute("data-sidebar-size", "sm") :
              document.documentElement.setAttribute("data-sidebar-size", "lg");
          } else if (windowSize <= 767) {
            document.body.classList.add("vertical-sidebar-enable");
            document.documentElement.setAttribute("data-sidebar-size", "lg");
          }
        }

        //Two column menu
        if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
          document.body.classList.contains("twocolumn-panel") ?
            document.body.classList.remove("twocolumn-panel") :
            document.body.classList.add("twocolumn-panel");
        }
      },
      toggleMenu() {
        this.$parent.toggleMenu();
      },
      toggleRightSidebar() {
        this.$parent.toggleRightSidebar();
      },
      initFullScreen() {
        document.body.classList.toggle("fullscreen-enable");
        if (
          !document.fullscreenElement &&
          /* alternative standard method */
          !document.mozFullScreenElement &&
          !document.webkitFullscreenElement
        ) {
          // current working methods
          if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
          } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(
              Element.ALLOW_KEYBOARD_INPUT
            );
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          }
        }
      },
      toggleDarkMode() {
        if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
          document.documentElement.setAttribute("data-layout-mode", "light");
        } else {
          document.documentElement.setAttribute("data-layout-mode", "dark");
        }
      },
    },
  };
</script>
<style scoped lang="scss">
.header-item {
  height: 60px;
}
.router-link-active > span {
    font-weight: 600;
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid white;
}

.nav-link {
  color: white;
}
</style>