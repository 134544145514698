<script>
localStorage.setItem("rightbar_isopen", false);
import { layoutMethods, layoutComputed } from "@/state/helpers";
import { SimpleBar } from "simplebar-vue3";

/**
 * Right sidebar component
 */
export default {
  components: {
    SimpleBar,
  },
  data() {
    return {
      config: {
        handler: this.handleRightBarClick,
        middleware: this.middleware,
        events: ["click"],
      },
    };
  },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
      set(layout) {
        localStorage.setItem("rightbar_isopen", true);
        this.changeLayoutType({
          layoutType: layout,
        });
      },
    },
    layoutWidth: {
      get() {
        return this.$store ? this.$store.state.layout.layoutWidth : {} || {};
      },
      set(width) {
        this.changeLayoutWidth({
          layoutWidth: width,
        });
      },
    },
    topbar: {
      get() {
        return this.$store ? this.$store.state.layout.topbar : {} || {};
      },
      set(topbar) {
        this.changeTopbar({
          topbar: topbar,
        });
      },
    },
    sidebarSize: {
      get() {
        return this.$store ? this.$store.state.layout.sidebarSize : {} || {};
      },
      set(type) {
        return this.changeSidebarSize({
          sidebarSize: type,
        });
      },
    },
    position: {
      get() {
        return this.$store ? this.$store.state.layout.position : {} || {};
      },
      set(position) {
        return this.changePosition({
          position: position,
        });
      },
    },
    sidebarView: {
      get() {
        return this.$store ? this.$store.state.layout.sidebarView : {} || {};
      },
      set(sidebarView) {
        return this.changeSidebarView({
          sidebarView: sidebarView,
        });
      },
    },
    sidebarColor: {
      get() {
        return this.$store ? this.$store.state.layout.sidebarColor : {} || {};
      },
      set(sidebarColor) {
        return this.changeSidebarColor({
          sidebarColor: sidebarColor,
        });
      },
    },
    mode: {
      get() {
        return this.$store ? this.$store.state.layout.mode : {} || {};
      },
      set(mode) {
        return this.changeMode({
          mode: mode,
        });
      },
    },
  },
  watch: {
    mode: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.documentElement.setAttribute("data-layout-mode", "dark");
              break;
            case "light":
              document.documentElement.setAttribute(
                "data-layout-mode",
                "light"
              );
              break;
          }
        }
      },
    },
    sidebarColor: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.documentElement.setAttribute("data-sidebar", "dark");
              break;
            case "light":
              document.documentElement.setAttribute("data-sidebar", "light");
              break;
          }
        }
      },
    },
    sidebarView: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "detached":
              document.documentElement.setAttribute(
                "data-layout-style",
                "detached"
              );
              break;
            case "default":
              document.documentElement.setAttribute(
                "data-layout-style",
                "default"
              );
              break;
          }
        }
      },
    },
    position: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "fixed":
              document.documentElement.setAttribute(
                "data-layout-position",
                "fixed"
              );
              break;
            case "scrollable":
              document.documentElement.setAttribute(
                "data-layout-position",
                "scrollable"
              );
              break;
          }
        }
      },
    },
    sidebarSize: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "lg":
              document.documentElement.setAttribute("data-sidebar-size", "lg");
              break;
            case "sm":
              document.documentElement.setAttribute("data-sidebar-size", "lg");
              break;
            case "md":
              document.documentElement.setAttribute("data-sidebar-size", "lg");
              break;
            case "sm-hover":
              document.documentElement.setAttribute(
                "data-sidebar-size",
                "sm-hover"
              );
              break;
          }
        }
      },
    },
    topbar: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "light":
              document.documentElement.setAttribute("data-topbar", "light");
              break;
            case "dark":
              document.documentElement.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    layoutWidth: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "fluid":
              document.documentElement.setAttribute(
                "data-layout-width",
                "fluid"
              );
              break;
            case "boxed":
              document.documentElement.setAttribute(
                "data-layout-width",
                "boxed"
              );
              break;
          }
        }
      },
    },
    layoutType: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "horizontal":
              document.documentElement.setAttribute(
                "data-layout",
                "horizontal"
              );
              break;
            case "vertical":
              document.documentElement.setAttribute("data-layout", "vertical");
              break;
            case "twocolumn":
              document.documentElement.setAttribute("data-layout", "twocolumn");
              break;
          }
        }
      },
    },
  },
  mounted(){
    let rightbar_isopen = localStorage.getItem('rightbar_isopen')
    if(rightbar_isopen=='true'){
      document.getElementById('mdi-cog').click()
      localStorage.setItem("rightbar_isopen", false);
    }
  },
  methods: {
    ...layoutMethods,
    hide() {
      this.$parent.toggleRightSidebar();
    },
    handleRightBarClick() {
      this.$parent.hideRightSidebar();
    },
    middleware(event) {
      if (event.target.classList)
        return !event.target.classList.contains("toggle-right");
    },
  },
};
</script>

<template>
  <div>
    <div
      id="theme-settings-offcanvas"
      class="offcanvas offcanvas-end border-0"
      tabindex="-1"
    >
      <div
        class="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header"
      >
        <h5 class="m-0 me-2 text-white">
          Theme Customizer
        </h5>
        <button
          type="button"
          class="btn-close btn-close-white ms-auto"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
      </div>
      <div class="offcanvas-body p-0">
        <SimpleBar class="h-100">
          <div class="p-4">
            <h6 class="mb-0 fw-semibold text-uppercase">
              Layout
            </h6>
            <p class="text-muted">
              Choose your layout
            </p>

            <div class="row">
              <div class="col-4">
                <div class="form-check card-radio">
                  <input
                    id="customizer-layout01"
                    v-model="layoutType"
                    name="data-layout"
                    type="radio"
                    value="vertical"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label p-0 avatar-md w-100"
                    for="customizer-layout01"
                  >
                    <span class="d-flex gap-1 h-100">
                      <span class="flex-shrink-0">
                        <span class="bg-light d-flex h-100 flex-column gap-1 p-1">
                          <span class="d-block p-1 px-2 bg-soft-primary rounded mb-2" />
                          <span class="d-block p-1 px-2 pb-0 bg-soft-primary" />
                          <span class="d-block p-1 px-2 pb-0 bg-soft-primary" />
                          <span class="d-block p-1 px-2 pb-0 bg-soft-primary" />
                        </span>
                      </span>
                      <span class="flex-grow-1">
                        <span class="d-flex h-100 flex-column">
                          <span class="bg-light d-block p-1" />
                          <span class="bg-light d-block p-1 mt-auto" />
                        </span>
                      </span>
                    </span>
                  </label>
                </div>
                <h5 class="fs-13 text-center mt-2">
                  Vertical
                </h5>
              </div>
              <div class="col-4">
                <div class="form-check card-radio">
                  <input
                    id="customizer-layout02"
                    v-model="layoutType"
                    name="data-layout"
                    type="radio"
                    value="horizontal"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label p-0 avatar-md w-100"
                    for="customizer-layout02"
                  >
                    <span class="d-flex h-100 flex-column gap-1">
                      <span
                        class="bg-light d-flex p-1 gap-1 align-items-center"
                      >
                        <span class="d-block p-1 bg-soft-primary rounded me-1" />
                        <span class="d-block p-1 pb-0 px-2 bg-soft-primary ms-auto" />
                        <span class="d-block p-1 pb-0 px-2 bg-soft-primary" />
                      </span>
                      <span class="bg-light d-block p-1" />
                      <span class="bg-light d-block p-1 mt-auto" />
                    </span>
                  </label>
                </div>
                <h5 class="fs-13 text-center mt-2">
                  Horizontal
                </h5>
              </div>
              <div class="col-4">
                <div class="form-check card-radio">
                  <input
                    id="customizer-layout03"
                    v-model="layoutType"
                    name="data-layout"
                    type="radio"
                    value="twocolumn"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label p-0 avatar-md w-100"
                    for="customizer-layout03"
                  >
                    <span class="d-flex gap-1 h-100">
                      <span class="flex-shrink-0">
                        <span class="bg-light d-flex h-100 flex-column gap-1">
                          <span class="d-block p-1 bg-soft-primary mb-2" />
                          <span class="d-block p-1 pb-0 bg-soft-primary" />
                          <span class="d-block p-1 pb-0 bg-soft-primary" />
                          <span class="d-block p-1 pb-0 bg-soft-primary" />
                        </span>
                      </span>
                      <span class="flex-shrink-0">
                        <span class="bg-light d-flex h-100 flex-column gap-1 p-1">
                          <span class="d-block p-1 px-2 pb-0 bg-soft-primary" />
                          <span class="d-block p-1 px-2 pb-0 bg-soft-primary" />
                          <span class="d-block p-1 px-2 pb-0 bg-soft-primary" />
                          <span class="d-block p-1 px-2 pb-0 bg-soft-primary" />
                        </span>
                      </span>
                      <span class="flex-grow-1">
                        <span class="d-flex h-100 flex-column">
                          <span class="bg-light d-block p-1" />
                          <span class="bg-light d-block p-1 mt-auto" />
                        </span>
                      </span>
                    </span>
                  </label>
                </div>
                <h5 class="fs-13 text-center mt-2">
                  Two Column
                </h5>
              </div>
              <!-- end col -->
            </div>

            <h6 class="mt-4 mb-0 fw-semibold text-uppercase">
              Color Scheme
            </h6>
            <p class="text-muted">
              Choose Light or Dark Scheme.
            </p>

            <div class="colorscheme-cardradio">
              <div class="row">
                <div class="col-4">
                  <div class="form-check card-radio">
                    <input
                      id="layout-mode-light"
                      v-model="mode"
                      class="form-check-input"
                      type="radio"
                      name="data-layout-mode"
                      value="light"
                    >
                    <label
                      class="form-check-label p-0 avatar-md w-100"
                      for="layout-mode-light"
                    >
                      <span class="d-flex gap-1 h-100">
                        <span class="flex-shrink-0">
                          <span
                            class="bg-light d-flex h-100 flex-column gap-1 p-1"
                          >
                            <span class="d-block p-1 px-2 bg-soft-primary rounded mb-2" />
                            <span class="d-block p-1 px-2 pb-0 bg-soft-primary" />
                            <span class="d-block p-1 px-2 pb-0 bg-soft-primary" />
                            <span class="d-block p-1 px-2 pb-0 bg-soft-primary" />
                          </span>
                        </span>
                        <span class="flex-grow-1">
                          <span class="d-flex h-100 flex-column">
                            <span class="bg-light d-block p-1" />
                            <span class="bg-light d-block p-1 mt-auto" />
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                  <h5 class="fs-13 text-center mt-2">
                    Light
                  </h5>
                </div>

                <div class="col-4">
                  <div class="form-check card-radio dark">
                    <input
                      id="layout-mode-dark"
                      v-model="mode"
                      class="form-check-input"
                      type="radio"
                      name="data-layout-mode"
                      value="dark"
                    >
                    <label
                      class="form-check-label p-0 avatar-md w-100 bg-dark"
                      for="layout-mode-dark"
                    >
                      <span class="d-flex gap-1 h-100">
                        <span class="flex-shrink-0">
                          <span
                            class="
                              bg-soft-light
                              d-flex
                              h-100
                              flex-column
                              gap-1
                              p-1
                            "
                          >
                            <span class="d-block p-1 px-2 bg-soft-light rounded mb-2" />
                            <span class="d-block p-1 px-2 pb-0 bg-soft-light" />
                            <span class="d-block p-1 px-2 pb-0 bg-soft-light" />
                            <span class="d-block p-1 px-2 pb-0 bg-soft-light" />
                          </span>
                        </span>
                        <span class="flex-grow-1">
                          <span class="d-flex h-100 flex-column">
                            <span class="bg-soft-light d-block p-1" />
                            <span class="bg-soft-light d-block p-1 mt-auto" />
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                  <h5 class="fs-13 text-center mt-2">
                    Dark
                  </h5>
                </div>
              </div>
            </div>

            <div
              v-if="layoutType != 'twocolumn'"
              id="layout-width"
            >
              <h6 class="mt-4 mb-0 fw-semibold text-uppercase">
                Layout Width
              </h6>
              <p class="text-muted">
                Choose Fluid or Boxed layout.
              </p>

              <div class="row">
                <div class="col-4">
                  <div class="form-check card-radio">
                    <input
                      id="layout-width-fluid"
                      v-model="layoutWidth"
                      class="form-check-input"
                      type="radio"
                      name="data-layout-width"
                      value="fluid"
                    >
                    <label
                      class="form-check-label p-0 avatar-md w-100"
                      for="layout-width-fluid"
                    >
                      <span class="d-flex gap-1 h-100">
                        <span class="flex-shrink-0">
                          <span class="bg-light d-flex h-100 flex-column gap-1 p-1">
                            <span class="d-block p-1 px-2 bg-soft-primary rounded mb-2" />
                            <span class="d-block p-1 px-2 pb-0 bg-soft-primary" />
                            <span class="d-block p-1 px-2 pb-0 bg-soft-primary" />
                            <span class="d-block p-1 px-2 pb-0 bg-soft-primary" />
                          </span>
                        </span>
                        <span class="flex-grow-1">
                          <span class="d-flex h-100 flex-column">
                            <span class="bg-light d-block p-1" />
                            <span class="bg-light d-block p-1 mt-auto" />
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                  <h5 class="fs-13 text-center mt-2">
                    Fluid
                  </h5>
                </div>
                <div class="col-4">
                  <div class="form-check card-radio">
                    <input
                      id="layout-width-boxed"
                      v-model="layoutWidth"
                      class="form-check-input"
                      type="radio"
                      name="data-layout-width"
                      value="boxed"
                    >
                    <label
                      class="form-check-label p-0 avatar-md w-100 px-2"
                      for="layout-width-boxed"
                    >
                      <span class="d-flex gap-1 h-100 border-start border-end">
                        <span class="flex-shrink-0">
                          <span class="bg-light d-flex h-100 flex-column gap-1 p-1">
                            <span class="d-block p-1 px-2 bg-soft-primary rounded mb-2" />
                            <span class="d-block p-1 px-2 pb-0 bg-soft-primary" />
                            <span class="d-block p-1 px-2 pb-0 bg-soft-primary" />
                            <span class="d-block p-1 px-2 pb-0 bg-soft-primary" />
                          </span>
                        </span>
                        <span class="flex-grow-1">
                          <span class="d-flex h-100 flex-column">
                            <span class="bg-light d-block p-1" />
                            <span class="bg-light d-block p-1 mt-auto" />
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                  <h5 class="fs-13 text-center mt-2">
                    Boxed
                  </h5>
                </div>
              </div>
            </div>

            <div
              v-if="layoutType != 'twocolumn'"
              id="layout-position"
            >
              <h6 class="mt-4 mb-0 fw-semibold text-uppercase">
                Layout Position
              </h6>
              <p class="text-muted">
                Choose Fixed or Scrollable Layout Position.
              </p>

              <div
                class="btn-group radio"
                role="group"
              >
                <input
                  id="layout-position-fixed"
                  v-model="position"
                  type="radio"
                  class="btn-check"
                  name="data-layout-position"
                  value="fixed"
                >
                <label
                  class="btn btn-light w-sm"
                  for="layout-position-fixed"
                >Fixed</label>

                <input
                  id="layout-position-scrollable"
                  v-model="position"
                  type="radio"
                  class="btn-check"
                  name="data-layout-position"
                  value="scrollable"
                >
                <label
                  class="btn btn-light w-sm ms-0"
                  for="layout-position-scrollable"
                >Scrollable</label>
              </div>
            </div>
            <h6 class="mt-4 mb-0 fw-semibold text-uppercase">
              Topbar Color
            </h6>
            <p class="text-muted">
              Choose Light or Dark Topbar Color.
            </p>

            <div class="row">
              <div class="col-4">
                <div class="form-check card-radio">
                  <input
                    id="topbar-color-light"
                    v-model="topbar"
                    class="form-check-input"
                    type="radio"
                    name="data-topbar"
                    value="light"
                  >
                  <label
                    class="form-check-label p-0 avatar-md w-100"
                    for="topbar-color-light"
                  >
                    <span class="d-flex gap-1 h-100">
                      <span class="flex-shrink-0">
                        <span
                          class="bg-light d-flex h-100 flex-column gap-1 p-1"
                        >
                          <span
                            class="
                              d-block
                              p-1
                              px-2
                              bg-soft-primary
                              rounded
                              mb-2
                            "
                          />
                          <span
                            class="d-block p-1 px-2 pb-0 bg-soft-primary"
                          />
                          <span
                            class="d-block p-1 px-2 pb-0 bg-soft-primary"
                          />
                          <span
                            class="d-block p-1 px-2 pb-0 bg-soft-primary"
                          />
                        </span>
                      </span>
                      <span class="flex-grow-1">
                        <span class="d-flex h-100 flex-column">
                          <span class="bg-light d-block p-1" />
                          <span class="bg-light d-block p-1 mt-auto" />
                        </span>
                      </span>
                    </span>
                  </label>
                </div>
                <h5 class="fs-13 text-center mt-2">
                  Light
                </h5>
              </div>
              <div class="col-4">
                <div class="form-check card-radio">
                  <input
                    id="topbar-color-dark"
                    v-model="topbar"
                    class="form-check-input"
                    type="radio"
                    name="data-topbar"
                    value="dark"
                  >
                  <label
                    class="form-check-label p-0 avatar-md w-100"
                    for="topbar-color-dark"
                  >
                    <span class="d-flex gap-1 h-100">
                      <span class="flex-shrink-0">
                        <span
                          class="bg-light d-flex h-100 flex-column gap-1 p-1"
                        >
                          <span
                            class="
                              d-block
                              p-1
                              px-2
                              bg-soft-primary
                              rounded
                              mb-2
                            "
                          />
                          <span
                            class="d-block p-1 px-2 pb-0 bg-soft-primary"
                          />
                          <span
                            class="d-block p-1 px-2 pb-0 bg-soft-primary"
                          />
                          <span
                            class="d-block p-1 px-2 pb-0 bg-soft-primary"
                          />
                        </span>
                      </span>
                      <span class="flex-grow-1">
                        <span class="d-flex h-100 flex-column">
                          <span class="bg-primary d-block p-1" />
                          <span class="bg-light d-block p-1 mt-auto" />
                        </span>
                      </span>
                    </span>
                  </label>
                </div>
                <h5 class="fs-13 text-center mt-2">
                  Dark
                </h5>
              </div>
            </div>

            <div
              v-if="layoutType == 'vertical'"
              id="sidebar-size"
            >
              <h6 class="mt-4 mb-0 fw-semibold text-uppercase">
                Sidebar Size
              </h6>
              <p class="text-muted">
                Choose a size of Sidebar.
              </p>

              <div class="row">
                <div class="col-4">
                  <div class="form-check sidebar-setting card-radio">
                    <input
                      id="sidebar-size-default"
                      v-model="sidebarSize"
                      class="form-check-input"
                      type="radio"
                      name="data-sidebar-size"
                      value="lg"
                    >
                    <label
                      class="form-check-label p-0 avatar-md w-100"
                      for="sidebar-size-default"
                    >
                      <span class="d-flex gap-1 h-100">
                        <span class="flex-shrink-0">
                          <span
                            class="bg-light d-flex h-100 flex-column gap-1 p-1"
                          >
                            <span
                              class="
                                d-block
                                p-1
                                px-2
                                bg-soft-primary
                                rounded
                                mb-2
                              "
                            />
                            <span
                              class="d-block p-1 px-2 pb-0 bg-soft-primary"
                            />
                            <span
                              class="d-block p-1 px-2 pb-0 bg-soft-primary"
                            />
                            <span
                              class="d-block p-1 px-2 pb-0 bg-soft-primary"
                            />
                          </span>
                        </span>
                        <span class="flex-grow-1">
                          <span class="d-flex h-100 flex-column">
                            <span class="bg-light d-block p-1" />
                            <span class="bg-light d-block p-1 mt-auto" />
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                  <h5 class="fs-13 text-center mt-2">
                    Default
                  </h5>
                </div>

                <div class="col-4">
                  <div class="form-check sidebar-setting card-radio">
                    <input
                      id="sidebar-size-compact"
                      v-model="sidebarSize"
                      class="form-check-input"
                      type="radio"
                      name="data-sidebar-size"
                      value="md"
                    >
                    <label
                      class="form-check-label p-0 avatar-md w-100"
                      for="sidebar-size-compact"
                    >
                      <span class="d-flex gap-1 h-100">
                        <span class="flex-shrink-0">
                          <span
                            class="bg-light d-flex h-100 flex-column gap-1 p-1"
                          >
                            <span
                              class="d-block p-1 bg-soft-primary rounded mb-2"
                            />
                            <span
                              class="d-block p-1 pb-0 bg-soft-primary"
                            />
                            <span
                              class="d-block p-1 pb-0 bg-soft-primary"
                            />
                            <span
                              class="d-block p-1 pb-0 bg-soft-primary"
                            />
                          </span>
                        </span>
                        <span class="flex-grow-1">
                          <span class="d-flex h-100 flex-column">
                            <span class="bg-light d-block p-1" />
                            <span class="bg-light d-block p-1 mt-auto" />
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                  <h5 class="fs-13 text-center mt-2">
                    Compact
                  </h5>
                </div>

                <div class="col-4">
                  <div class="form-check sidebar-setting card-radio">
                    <input
                      id="sidebar-size-small"
                      v-model="sidebarSize"
                      class="form-check-input"
                      type="radio"
                      name="data-sidebar-size"
                      value="sm"
                    >
                    <label
                      class="form-check-label p-0 avatar-md w-100"
                      for="sidebar-size-small"
                    >
                      <span class="d-flex gap-1 h-100">
                        <span class="flex-shrink-0">
                          <span class="bg-light d-flex h-100 flex-column gap-1">
                            <span
                              class="d-block p-1 bg-soft-primary mb-2"
                            />
                            <span
                              class="d-block p-1 pb-0 bg-soft-primary"
                            />
                            <span
                              class="d-block p-1 pb-0 bg-soft-primary"
                            />
                            <span
                              class="d-block p-1 pb-0 bg-soft-primary"
                            />
                          </span>
                        </span>
                        <span class="flex-grow-1">
                          <span class="d-flex h-100 flex-column">
                            <span class="bg-light d-block p-1" />
                            <span class="bg-light d-block p-1 mt-auto" />
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                  <h5 class="fs-13 text-center mt-2">
                    Small (Icon View)
                  </h5>
                </div>

                <div class="col-4">
                  <div class="form-check sidebar-setting card-radio">
                    <input
                      id="sidebar-size-small-hover"
                      v-model="sidebarSize"
                      class="form-check-input"
                      type="radio"
                      name="data-sidebar-size"
                      value="sm-hover"
                    >
                    <label
                      class="form-check-label p-0 avatar-md w-100"
                      for="sidebar-size-small-hover"
                    >
                      <span class="d-flex gap-1 h-100">
                        <span class="flex-shrink-0">
                          <span class="bg-light d-flex h-100 flex-column gap-1">
                            <span
                              class="d-block p-1 bg-soft-primary mb-2"
                            />
                            <span
                              class="d-block p-1 pb-0 bg-soft-primary"
                            />
                            <span
                              class="d-block p-1 pb-0 bg-soft-primary"
                            />
                            <span
                              class="d-block p-1 pb-0 bg-soft-primary"
                            />
                          </span>
                        </span>
                        <span class="flex-grow-1">
                          <span class="d-flex h-100 flex-column">
                            <span class="bg-light d-block p-1" />
                            <span class="bg-light d-block p-1 mt-auto" />
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                  <h5 class="fs-13 text-center mt-2">
                    Small Hover View
                  </h5>
                </div>
              </div>
            </div>

            <div
              v-if="layoutType == 'vertical'"
              id="sidebar-view"
            >
              <h6 class="mt-4 mb-0 fw-semibold text-uppercase">
                Sidebar View
              </h6>
              <p class="text-muted">
                Choose Default or Detached Sidebar view.
              </p>

              <div class="row">
                <div class="col-4">
                  <div class="form-check sidebar-setting card-radio">
                    <input
                      id="sidebar-view-default"
                      v-model="sidebarView"
                      class="form-check-input"
                      type="radio"
                      name="data-layout-style"
                      value="default"
                    >
                    <label
                      class="form-check-label p-0 avatar-md w-100"
                      for="sidebar-view-default"
                    >
                      <span class="d-flex gap-1 h-100">
                        <span class="flex-shrink-0">
                          <span
                            class="bg-light d-flex h-100 flex-column gap-1 p-1"
                          >
                            <span
                              class="
                                d-block
                                p-1
                                px-2
                                bg-soft-primary
                                rounded
                                mb-2
                              "
                            />
                            <span
                              class="d-block p-1 px-2 pb-0 bg-soft-primary"
                            />
                            <span
                              class="d-block p-1 px-2 pb-0 bg-soft-primary"
                            />
                            <span
                              class="d-block p-1 px-2 pb-0 bg-soft-primary"
                            />
                          </span>
                        </span>
                        <span class="flex-grow-1">
                          <span class="d-flex h-100 flex-column">
                            <span class="bg-light d-block p-1" />
                            <span class="bg-light d-block p-1 mt-auto" />
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                  <h5 class="fs-13 text-center mt-2">
                    Default
                  </h5>
                </div>
                <div class="col-4">
                  <div class="form-check sidebar-setting card-radio">
                    <input
                      id="sidebar-view-detached"
                      v-model="sidebarView"
                      class="form-check-input"
                      type="radio"
                      name="data-layout-style"
                      value="detached"
                    >
                    <label
                      class="form-check-label p-0 avatar-md w-100"
                      for="sidebar-view-detached"
                    >
                      <span class="d-flex h-100 flex-column">
                        <span
                          class="
                            bg-light
                            d-flex
                            p-1
                            gap-1
                            align-items-center
                            px-2
                          "
                        >
                          <span
                            class="d-block p-1 bg-soft-primary rounded me-1"
                          />
                          <span
                            class="
                              d-block
                              p-1
                              pb-0
                              px-2
                              bg-soft-primary
                              ms-auto
                            "
                          />
                          <span
                            class="d-block p-1 pb-0 px-2 bg-soft-primary"
                          />
                        </span>
                        <span class="d-flex gap-1 h-100 p-1 px-2">
                          <span class="flex-shrink-0">
                            <span
                              class="
                                bg-light
                                d-flex
                                h-100
                                flex-column
                                gap-1
                                p-1
                              "
                            >
                              <span
                                class="d-block p-1 px-2 pb-0 bg-soft-primary"
                              />
                              <span
                                class="d-block p-1 px-2 pb-0 bg-soft-primary"
                              />
                              <span
                                class="d-block p-1 px-2 pb-0 bg-soft-primary"
                              />
                            </span>
                          </span>
                        </span>
                        <span class="bg-light d-block p-1 mt-auto px-2" />
                      </span>
                    </label>
                  </div>
                  <h5 class="fs-13 text-center mt-2">
                    Detached
                  </h5>
                </div>
              </div>
            </div>
            <div
              v-if="layoutType != 'horizontal'"
              id="sidebar-color"
            >
              <h6 class="mt-4 mb-0 fw-semibold text-uppercase">
                Sidebar Color
              </h6>
              <p class="text-muted">
                Choose Ligth or Dark Sidebar Color.
              </p>

              <div class="row">
                <div class="col-4">
                  <div class="form-check sidebar-setting card-radio">
                    <input
                      id="sidebar-color-light"
                      v-model="sidebarColor"
                      class="form-check-input"
                      type="radio"
                      name="data-sidebar"
                      value="light"
                    >
                    <label
                      class="form-check-label p-0 avatar-md w-100"
                      for="sidebar-color-light"
                    >
                      <span class="d-flex gap-1 h-100">
                        <span class="flex-shrink-0">
                          <span class="bg-white border-end d-flex h-100 flex-column gap-1 p-1">
                            <span
                              class="
                                d-block
                                p-1
                                px-2
                                bg-soft-primary
                                rounded
                                mb-2
                              "
                            />
                            <span
                              class="d-block p-1 px-2 pb-0 bg-soft-primary"
                            />
                            <span
                              class="d-block p-1 px-2 pb-0 bg-soft-primary"
                            />
                            <span
                              class="d-block p-1 px-2 pb-0 bg-soft-primary"
                            />
                          </span>
                        </span>
                        <span class="flex-grow-1">
                          <span class="d-flex h-100 flex-column">
                            <span class="bg-light d-block p-1" />
                            <span class="bg-light d-block p-1 mt-auto" />
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                  <h5 class="fs-13 text-center mt-2">
                    Light
                  </h5>
                </div>
                <div class="col-4">
                  <div class="form-check sidebar-setting card-radio">
                    <input
                      id="sidebar-color-dark"
                      v-model="sidebarColor"
                      class="form-check-input"
                      type="radio"
                      name="data-sidebar"
                      value="dark"
                    >
                    <label
                      class="form-check-label p-0 avatar-md w-100"
                      for="sidebar-color-dark"
                    >
                      <span class="d-flex gap-1 h-100">
                        <span class="flex-shrink-0">
                          <span
                            class="
                              bg-primary
                              d-flex
                              h-100
                              flex-column
                              gap-1
                              p-1
                            "
                          >
                            <span
                              class="
                                d-block
                                p-1
                                px-2
                                bg-soft-light
                                rounded
                                mb-2
                              "
                            />
                            <span
                              class="d-block p-1 px-2 pb-0 bg-soft-light"
                            />
                            <span
                              class="d-block p-1 px-2 pb-0 bg-soft-light"
                            />
                            <span
                              class="d-block p-1 px-2 pb-0 bg-soft-light"
                            />
                          </span>
                        </span>
                        <span class="flex-grow-1">
                          <span class="d-flex h-100 flex-column">
                            <span class="bg-light d-block p-1" />
                            <span class="bg-light d-block p-1 mt-auto" />
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                  <h5 class="fs-13 text-center mt-2">
                    Dark
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </SimpleBar>
      </div>
      <div class="offcanvas-footer border-top p-3 text-center">
        <div class="row">
          <div class="col-6">
            <button
              id="reset-layout"
              type="button"
              class="btn btn-light w-100"
            >
              Reset
            </button>
          </div>
          <div class="col-6">
            <button
              type="button"
              class="btn btn-primary w-100"
            >
              Preview
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
