<template>
  <div class="container-fluid">
    <ul
      id="navbar-nav"
      class="navbar-nav h-100"
    >
      <li class="menu-title text-white">
        <span data-key="t-pages">ORGANIZATION</span>
      </li>
      <li class="nav-item mb-3">
        <b-input-group class="mb-0 ms-3">
          <template #prepend>
            <b-input-group-text class="prepend-outline-secondary bg-white">
              Organization
            </b-input-group-text>
          </template>
          <OrganizationSelector
            class="bg-white"
            style="border-top-right-radius: 4px; border-bottom-right-radius: 4px;"
          />
        </b-input-group>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link menu-link"
          :class="isParentNode('dashboard') ? 'active router-link-active' : ''"
          to="/"
        >
          <i class="mdi mdi-view-dashboard-outline" />
          <span>DASHBOARD</span>
        </router-link>
      </li>
      <li class="nav-item">
        <div
          class="nav-link menu-link"
          href="#sidebarFleet"
          data-bs-toggle="collapse"
          role="button"
          aria-controls="sidebarFleet"
          :class="isParentNode('fleet') ? 'active router-link-active' : ''"
        >
          <i class="mdi mdi-truck-outline" />
          <span>FLEET</span>
        </div>
        <div
          id="sidebarFleet"
          class="menu-dropdown collapse"
          style=""
        >
          <ul class="flex-column">
            <li class="nav-item">
              <router-link
                class="nav-link menu-link"
                to="/fleet-management"
              >
                <span>Fleet Management</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link menu-link"
                to="/fleet-realtime"
              >
                <span>Realtime Status</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link menu-link"
                to="/fleet-emission"
              >
                <span>Carbon Emission Report</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link menu-link"
          :class="isParentNode('site') ? 'active router-link-active' : ''"
          to="/site"
        >
          <i class="bx bx-buildings" />
          <span>SITE</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link menu-link"
          :class="isParentNode('organization') ? 'active router-link-active' : ''"
          :to="profile.is_superadmin ? '/organization' : '/organization-detail'"
        >
          <i class="mdi mdi-rhombus-outline" />
          <span>ORGANIZATION</span>
        </router-link>
      </li>
      <!-- <li class="nav-item">
        <hr class="bg-white mx-3">
      </li>  -->
    </ul>
  </div>
  <!-- Sidebar -->
</template>

<script>
  import { mapState } from 'vuex';
  import { layoutComputed } from "@/state/helpers";
  import OrganizationSelector from '@/components/OrganizationSelector.vue';
  export default {
    components: {
      OrganizationSelector
    },
    data() {
      return {
        settings: {
          minScrollbarLength: 60,
        },
      };
    },
    computed: {
      ...mapState('auth', ['profile']),
      ...layoutComputed,      
      selectedOrganization() {
        return this.$store.state.organization.selectedOrganization;
      },
    },

    watch: {
      $route: {
        handler: "onRoutechange",
        immediate: true,
        deep: true,
      },
    },

    mounted() {
      if (document.querySelectorAll(".navbar-nav .collapse")) {
        let collapses = document.querySelectorAll(".navbar-nav .collapse");
        collapses.forEach((collapse) => {
          // Hide sibling collapses on `show.bs.collapse`
          collapse.addEventListener("show.bs.collapse", (e) => {
            e.stopPropagation();
            let closestCollapse = collapse.parentElement.closest(".collapse");
            if (closestCollapse) {
              let siblingCollapses =
                closestCollapse.querySelectorAll(".collapse");
              siblingCollapses.forEach((siblingCollapse) => {
                if (siblingCollapse.classList.contains("show")) {
                  let aChild = siblingCollapse.parentNode.firstChild;
                  if (aChild.hasAttribute("aria-expanded")){
                    aChild.setAttribute("aria-expanded", "false");
                  }
                  siblingCollapse.classList.remove("show");
                }
              });
            } else {
              let getSiblings = (elem) => {
                // Setup siblings array and get the first sibling
                let siblings = [];
                let sibling = elem.parentNode.firstChild;
                // Loop through each sibling and push to the array
                while (sibling) {
                  if (sibling.nodeType === 1 && sibling !== elem) {
                    siblings.push(sibling);
                  }
                  sibling = sibling.nextSibling;
                }
                return siblings;
              };
              let siblings = getSiblings(collapse.parentElement);
              siblings.forEach((item) => {
                if (item.childNodes.length > 2)
                  item.firstElementChild.setAttribute("aria-expanded", "false");
                let ids = item.querySelectorAll("*[id]");
                ids.forEach((item1) => {
                  let aChild = item1.parentNode.firstChild;
                  if (aChild.hasAttribute("aria-expanded")){
                    aChild.setAttribute("aria-expanded", "false");
                  }
                  item1.classList.remove("show");
                  if (item1.childNodes.length > 2) {
                    let val = item1.querySelectorAll("ul li a");

                    val.forEach((subitem) => {
                      if (subitem.hasAttribute("aria-expanded"))
                        subitem.setAttribute("aria-expanded", "false");
                    });
                  }
                });
              });
            }
          });

          // Hide nested collapses on `hide.bs.collapse`
          collapse.addEventListener("hide.bs.collapse", (e) => {
            e.stopPropagation();
            let childCollapses = collapse.querySelectorAll(".collapse");
            childCollapses.forEach((childCollapse) => {
              let childCollapseInstance = childCollapse;
              childCollapseInstance.hide();
            });
          });
        });
      }
    },

    methods: {
      isParentNode(name) {
        const routeName = this.$route.name;
        const parentName = routeName.split('-')[0]
        return parentName == name
      },

      onRoutechange(ele) {
        this.initActiveMenu(ele.path);
        if (document.getElementsByClassName("mm-active").length > 0) {
          const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
          if (currentPosition > 500)
            if (this.$refs.isSimplebar)
              this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
        }
      },

      initActiveMenu(ele) {
        setTimeout(() => {
          if (document.querySelector("#navbar-nav")) {
            let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');

            if (a) {
              a.classList.add("active");
              let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
              if (parentCollapseDiv) {
                parentCollapseDiv.classList.add("show");
                parentCollapseDiv.parentElement.children[0].classList.add("active");
                parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
                if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                  parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                  if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                    parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add(
                      "active");
                }
              }
            }
          }
        }, 0);
      },
    },
  };
</script>

<style scoped>
.router-link-active > span {
    content: "";
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid white;
}
</style>