<template>
  <div class="d-flex">
    <div class="my-auto">
      <b-input-group style="min-width: 200px;">
        <Multiselect
          v-model="selectedOrganization"
          placeholder="Select Organization"
          class="form-select"
          :can-deselect="false"
          :close-on-select="true"
          :searchable="true"
          :create-option="false"
          :can-clear="false"
          :options="organizationOptions"
        >
          <template #caret>
            <span class="custom-caret">
              <i class="bx bx-chevron-down my-auto fs-4" />
            </span>
          </template>
        </Multiselect>
      </b-input-group>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"

import { mapState } from 'vuex';
import { OrganizationService } from '@/services/local-storage';

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedOrganization: null,
    };
  },
  computed: {
    ...mapState('organization', ['organizationList']),
    ...mapState('auth', ['profile']),

    userMainOrganization() {
      if (this.profile.organizations) {
        return this.profile.organizations[0]?.id;
      }
      return 0;
    },
    
    organizationOptions() {
      if (this.profile.is_superadmin) {
        return this.organizationList.map(item => ({
          label: item.name,
          value: item.id
        }))
      }
      const userOrganizations = this.profile.organizations;
      if (userOrganizations) {
        return userOrganizations.map(item => ({
          label: item.name,
          value: item.id
        }))
      }
      return []
    },
  },

  watch: {
    selectedOrganization: {
      immediate: false,
      handler(organizationId) {
        this.getOrganizationDetail(organizationId);
      },
    },
  },

  created() {
    const selectedOrganization = OrganizationService.get();
    
    if(selectedOrganization != null) {
      this.selectedOrganization = selectedOrganization
    } else {
      this.selectedOrganization = this.userMainOrganization
    }
  },

  mounted() {
    if(this.profile.is_superadmin) {
      this.$store.dispatch('organization/getOrganizationList');
    }
    this.$store.dispatch('auth/getProfile');
  },

  methods: {
    async getOrganizationDetail(organizationId) {
      try {
        const res = await this.$api.getOrganizationDetail(organizationId);
        const organizationDetail = res.data.data;
        this.$store.commit('organization/SET_ORGANIZATION_DETAIL', organizationDetail);
        // Set Selected Organization
        OrganizationService.set(organizationId)
        this.selectedOrganization = organizationId
        this.$store.commit('organization/SET_SELECTED_ORGANIZATION', organizationId);
      } catch (error) {
        console.log(error)
        this.selectedOrganization  = this.userMainOrganization
      }
    },
  },
};
</script>

<style scoped>
.form-select {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
</style>